import React from "react";
import handleShowPopUPForm from "../Fuction";

const TakeYourBusinessOnline = () => {
  return (
    <div className="">
      <div className=" w-[90%] max-lg:w-[80%] mt-8 flex max-md:flex-col items-center xl:max-w-screen-xl mx-auto ">
        <div className="md:w-1/2">
          <h1 className="headingcolor text-2xl max-md:text-center  md:text-3xl lg:text-5xl font-bold">Are You Ready To Take Your Business Online ?</h1>
          <p className="mt-8 font-medium max-md:text-center text-paraColor">
            Now is the moment to create a distinctive online identity that
            distinguishes you from competitors. Reach out to us today to explore
            your website development and design requirements, and allow us to
            turn your digital aspirations into tangible reality.
          </p>
          <button
            className="mt-4 max-md:mx-auto  block hover:scale-[.9] transition-all duration-300 bg-orange-500 border text-white font-bold px-4 py-2  text-lg rounded-lg"
            onClick={handleShowPopUPForm}
          >
            Get A Free Consultation
          </button>
        </div>
        <div className="md:w-1/2">
            <img className="w-full md:h-[500px]" src="/online-data-analysis-flat-illustration-of-data-analytics-vector.jpg" alt="loading..." />
        </div>
      </div>
    </div>
  );
};

export default TakeYourBusinessOnline;
