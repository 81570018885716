import React from "react";
import HeroForm from "./HeroForm";
import { IoCloseSharp } from "react-icons/io5";

const PopUpForm = () => {
  const handleClose = () => {
    document.querySelector("#mainPopUpForm").classList.remove("translate-y-0");
    document.querySelector("#mainPopUpForm").classList.add("-translate-y-[100vh]");

    setTimeout(() => {
      document.querySelector("#PopUpForm").classList.add("hidden");
      document.querySelector("body").classList.remove("overflow-hidden");
    }, 300);
    setTimeout(() => {
      document.querySelector("#mainPopUpForm").classList.add("translate-y-0");
      document.querySelector("#mainPopUpForm").classList.remove("-translate-y-[100vh]");
    },300);
  };

  return (
    <div id="PopUpForm" className="hidden ">
      {/* hidden */}
      <div className=" fixed  z-50 flex inset-0 bg-black/40 backdrop-blur-[4px] overflow-hidden">
        <div
          id="mainPopUpForm"
          className="mainPopUpForm transition-all  m-auto relative  px-8 py-12 rounded-lg bg-gray-300  "
        >
          <button
            className=" absolute top-4 right-4"
            onClick={() => {
              handleClose();
            }}
          >
            <IoCloseSharp className="" />
          </button>

          <img
            width="215"
            height="67"
            loading="lazy"
            src="/logo.png"
            className=" max-sm:w-[150px] mx-a uto"
            alt=""
          />
          <h1 className="text-2xl font-bold">
            Would you like to Receive Callback in 28 Seconds?
          </h1>
          <HeroForm popup={true} />
          <p className="mt-2 max-md:text-[12px] text-gray-600 text-center">
            Our Experts Will Get In Touch With You In Next 28 Seconds.
          </p>
        </div>
      </div>
    </div>
  );
};
export default PopUpForm;
