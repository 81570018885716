import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const TrustedBrand = () => {
  const imgdata = [
    "/Brand/Brand (1).png",
    "/Brand/Brand (2).png",
    "/Brand/Brand (3).png",
    "/Brand/Brand (4).png",
    "/Brand/Brand (1).webp",
    "/Brand/Brand (2).webp",
    "/Brand/Brand (3).webp",
    "/Brand/Brand (4).webp",
    "/Brand/Brand (5).webp",
  ];

  return (
    <div id="">
      <div className="mt-12">
        <h1 className="headingcolor my-4 font-semibold  text-2xl md:text-3xl text-center">
          Trusted By 4000+ Brands & Businesses
        </h1>

        <Swiper
          spaceBetween={30}
          slidesPerView={7}
          centeredSlides={true}
          loop={true}
          speed={3000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          pagination={false}
          breakpoints={{
            1024: {
              slidesPerView: 7,
            },
            768: {
              slidesPerView: 4,
            },
            300: {
              slidesPerView: 3,
            },
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="trustedBrandsSwiper  w-[90%] mx-auto  "
        >
          {imgdata.map((val, i) => (
            <SwiperSlide key={i}>
              <div className="min-h-[100px]  flex items-center justify-center">
                <img className="w-[100px]  " src={val} alt="loading..." />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default TrustedBrand;
