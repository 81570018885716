import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdMenu } from "react-icons/md";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const headerLinks = [
    {
      text: "Website Designing & Development",
      url: "/",
    },
    {
      text: "eCommerce Development",
      url: "/",
    },
    {
      text: "Digital Marketing",
      url: "/",
    },
  ];

  const handleShowForm = () => {
    document.querySelector("#PopUpForm").classList.remove("hidden");
    document.querySelector("body").classList.add("overflow-hidden");

    // document.querySelector("#mainPopUpForm").classList.add("translate-y-0");
    // document.querySelector("#mainPopUpForm").classList.remove("translate-y-[100vh]");
  };

  return (
    <header>
      <nav className="raleway">
        <div className="  bg-[#bcbcbc]/40 px-5 py-2 flex justify-between items-center">
          <a href="https://thegronity.com/">
           <img
            width="215"
            height="67"
            loading="lazy"
            src="/logo.png"
            className=" max-sm:w-[150px]"
            alt=""
          /> 
          </a>
          
          <div className=" max-[1100px]:hidden flex justify-between items-center gap-8 text-[14px] lg:text-lg">
            {headerLinks.map((val, i) => {
              return (
                <button
                  className="font-semibold hover:text-[#FE0467]"
                  key={i}
                  onClick={handleShowForm}
                >
                  {val.text}
                </button>
              );
            })}
          </div>
          <div className="flex gap-3 md:gap-8">
            <button
              className="zoombutton max-md:text-[10px] font-bold bg-orange-500 px-2 md:px-4 py-2 text-white rounded"
              onClick={handleShowForm}
            >
             Request A Proposal
            </button>
            <button className=" hidden">
            {/* max-lg:block */}
              <MdMenu
                className="text-3xl"
                onClick={() => {
                  setMenu(!menu);
                }}
              />
            </button>
          </div>
        </div>
        <div
          className={`lg:hidden grid transition-all  bg-orange-500 text-white ${
            menu ? " grid-rows-[1fr]" : "grid-rows-[0fr]"
          } `}
        >
          <div className="overflow-hidden">
            <div className="py-4  min-w-[30%] flex  flex-col text-center">
              {headerLinks.map((val, i) => {
                return (
                  <button
                    className="py-1 font-semibold hover:text-[#FE0467]"
                    key={i}
                  >
                    {val.text}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
