import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
const Faq = () => {
  const [show, setShow] = useState(null);
  const data = [
    {
      question: "Why is it essential for my business to have a website?",
      answer:
        "A website is crucial for modern businesses, providing a platform to establish an online presence, reach a broader audience, and showcase products or services around the clock.",
    },
    {
      question: "What advantages does a website offer to small businesses?",
      answer:
        "Websites empower small businesses to compete on a global scale by leveling the playing field. They help in reaching a diverse audience, building brand credibility, and delivering convenient information to potential customers.",
    },
    {
      question:
        "In what ways does a website contribute to building credibility and trust?",
      answer:
        "A professionally designed website, featuring relevant content, testimonials, and contact information, instills confidence in visitors. It signals that your business is legitimate, fostering trust with potential customers.",
    },
    {
      question:
        "Can a website effectively enhance brand visibility and recognition?",
      answer:
        "Certainly! Through strategic SEO and online marketing, your website can achieve higher rankings in search engine results, making it simpler for people to discover your brand and improve your online visibility.",
    },
    {
      question:
        "What role does a website play in generating leads and conversions?",
      answer:
        "Your website serves as a potent tool for lead generation. By incorporating lead capture forms, compelling calls-to-action, and informative content, you can convert visitors into qualified leads and ultimately into paying customers.",
    },
    {
      question: "Is it crucial for a website to be mobile-responsive?",
      answer:
        "Yes, it is essential! With the majority of internet users accessing websites through mobile devices, a mobile-responsive website ensures a seamless user experience, enhances engagement, and positively impacts search engine rankings.",
    },
    {
      question:
        "How does a website help businesses stay ahead of the competition?",
      answer:
        "A well-designed website with engaging content and user-friendly features sets your business apart from competitors. It allows you to showcase unique selling points, effectively communicate your value proposition, and stand out in the crowded online marketplace.",
    },
    // {
    //   question: "Do websites benefit service-based businesses as well?",
    //   answer:
    //     "Absolutely! Websites enable service-based businesses to showcase their offerings, provide detailed service descriptions, display client testimonials, and facilitate easy communication for potential clients to inquire about services.",
    // },
    // {
    //   question: "Can a website assist in tracking and analyzing user behavior?",
    //   answer:
    //     "Yes, through web analytics tools, valuable insights into user behavior such as website traffic, page views, click-through rates, and conversion rates can be gained. This data aids in making data-driven decisions to optimize your website and marketing strategies.",
    // },
    // {
    //   question: "How does a website contribute to long-term business growth?",
    //   answer:
    //     "A website serves as a long-term asset for your business. Consistent optimization, creation of valuable content, and driving traffic transform it into a powerful lead generation and customer acquisition tool over time.",
    // },
  ];

  const handleShowAnswer = (index) => {
    setShow((prev) => (prev === index ? null : index));
  };

  return (
    <div className="py-8 bg-no-repeat  bg-center raleway "
    style={{
      background:"url(/faq-banner.jpg)"
    }}
    >
      <div className="text-center">
        <h1 className="text-xl md:text-4xl font-bold text-white ">Frequently Asked Questions</h1>
        <p className="mt-2 max-md:text-[12px] text-white">
          We build & manage world’s leading corporate websites.
        </p>
      </div>

      <div className="xl:max-w-screen-lg mx-auto p-4 md:p-8  mt-4 bg-[#bcbcbc]/30 rounded">
        {data.map((val, i) => {
          return (
            <div key={i} className=" mt-2 rounded  py-2 px-2 md:px-4 bg-white">
              <button
                className="flex justify-between gap-4  w-full"
                onClick={() => {
                  handleShowAnswer(i);
                }}
              >
                <h1 className="text-[14px] text-left md:text-2xl">{val.question}</h1>
                <FaAngleDown
                  className={` self-center ${
                    i === show ? "rotate-180" : ""
                  } transition-all `}
                />
              </button>
              <div
                className={`grid transition-all ${
                  i === show ? " grid-rows-[1fr]" : "grid-rows-[0fr]"
                }`}
              >
                <div className="overflow-hidden">
                  <p className="p-2  max-md:text-[12px] text-gray-500">{val.answer}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
