export default function handleShowPopUPForm() {
  document.querySelector("#PopUpForm").classList.remove("hidden");
  document.querySelector("body").classList.add("overflow-hidden");

  document.querySelector("#mainPopUpForm").classList.add("translate-y-0");
    document
      .querySelector("#mainPopUpForm")
      .classList.remove("-translate-y-[100vh]");

  // document.querySelector("#mainPopUpForm").classList.add("translate-y-0");
  // document.querySelector("#mainPopUpForm").classList.remove("translate-y-[100vh]");
  // setTimeout(() => {
  //   document.querySelector("#mainPopUpForm").classList.add("translate-y-0");
  //   document
  //     .querySelector("#mainPopUpForm")
  //     .classList.remove("translate-y-[-100vh]");
  // }, 1000);
}
