import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import TrustedBrand from "../components/TrustedBrand";
import OurWork from "../components/OurWork";
import ImageCarousel from "../components/ImageCarousel/ImageCarousel";
import ImageSlider from "../components/ImageCarousel/ImageSlider";
import ReviewCarousel from "../components/ReviewCarousel/ReviewCarousel";
import PopUpForm from "../components/Form/PopUpForm";
import FreeConsultation from "../components/FreeConsultation";
import TakeYourBusinessOnline from "../components/TakeYourBusinessOnline";
import DiscussionWithExperts from "../components/DiscussionWithExperts";
import Faq from "../components/Faq/Faq";
import Portfolio from "../components/Portfolio/Portfolio";
import BottomPopUp from "../components/RightBottomPopUp/BottomPopUp";
import Loadable from "react-loadable";

import { Helmet } from "react-helmet";
import Loader from "../components/Loader";
import SentaClause from "../components/SentaClause";

const LandingPage = () => {
  // const Loading = () => (
  //   <div className="w-full   h-[calc(100vh-62px)] flex justify-center items-center">
  //     <Loader />
  //   </div>
  // );
  // const VideoComponent = Loadable({
  //   loader: () => import("../components/Video"),
  //   loading: Loading,
  // });

  return (
    <>
      <Helmet>
        {/* Facebook Pixel Code */}
        <script>{`
          !function(f,b,e,v,n,t,s){
            if(f.fbq)return;
            n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
          
          fbq('init', '3010607305874228');
          fbq('track', 'PageView');
        `}</script>

        {/* Noscript fallback */}
        <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=3010607305874228&ev=PageView&noscript=1"
            alt="Facebook Pixel"
          />
        `}</noscript>
      </Helmet>
      <Header />
      <main className=" relative ">
        {/* <VideoComponent /> */}
        <div  >
        {/* className="hidden"  id="landingPage"*/}
          <Hero />
          <TrustedBrand />
          <OurWork />
          <TakeYourBusinessOnline />
          <FreeConsultation />
          <ImageCarousel />
          <Portfolio />
          <DiscussionWithExperts />
          <ReviewCarousel />
          <Faq />
          <PopUpForm />
          <SentaClause/>
          <a
            className="fixed z-50 right-4 bottom-4 "
            href="https://api.whatsapp.com/send/?phone=917669177583&text=Hi%21+I+have+a+question+about...&type=phone_number&app_absent=0"
          >
            <img
              className="w-[50px]"
              src="/WhatsApp_icon.png.webp"
              alt="loading..."
            />
          </a>
          <BottomPopUp />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LandingPage;
