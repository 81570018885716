import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HeroForm = ({ popup }) => {
  const form = useRef();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    business: "",
    phone: "",
    email: "",
    services: "Choose A Services",
    domain: "",
    other: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateMobileNumber = (mobileNumber) => {
    var regex = /^[0-9]{10}$/;
    console.log(regex.test(mobileNumber));
    return regex.test(mobileNumber);
  };
  const serviceId = "service_1shc1lbdf115dfg5";
  const templateId = "template_xq1qe2p";
  const publicKey = "7K00kuN4UvNZUKwPE";
 

  const sendEmail = (e) => {
    e.preventDefault();
  

    if (!validateMobileNumber(formData.phone)) {
      
        return toast.error("Please enter a valid phone no.");
    }

    let message;
    if (formData.services === "Others") {
      message = `${formData.other} `;
    } else if (formData.services === "Website Redesigning") {
      message = `${formData.services} and my domain is ${formData.domain}`;
    } else {
      message = formData.services;
    }

    const formDataCopy = { ...formData, message };
    console.log("copy", formDataCopy);
    emailjs
      .send(serviceId, templateId, formDataCopy, publicKey)
      .then((response) => {
        setFormData({
          name: "",
          business: "",
          phone: "",
          email: "",
          services: "Choose A Services",
          domain: "",
          other: "",
          message: "",
        });
        toast.success("Form submitted successfully!");
        navigate("/thank-you");
      })
      .catch((error) => {
        console.error("Error in sending form data:", error);
        toast.error(error);
      });
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
        <div>
          <div
            className={`mt-4 ${
              popup ? "grid grid-cols-1 md:grid-cols-2 gap-2" : ""
            }`}
          >
            <div className="mt-1 ">
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-1 ">
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="text"
                name="business"
                id="business"
                placeholder="Company / Business Name"
                value={formData.business}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-1">
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="tel"
                name="phone"
                id="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mt-1">
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className={`mt-1 text-gray-500/80 ${popup ? "mt-3" : ""}`}>
            <select
              className=" w-full py-[6px] bg-white  border px-4 rounded focus:border-blue-500 focus:border outline-none"
              name="services"
              id="services"
              value={formData.services}
              onChange={handleChange}
              required
            >
              <option value="Choose A Services">Choose A Services</option>
              <option value="Website Designing & Development">
                Website Designing & Development
              </option>
              <option value="Website Redesigning">Website Redesigning</option>
              <option value="Online Store/eCommerce Development">
                Online Store/eCommerce Development
              </option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Others">Others</option>
            </select>
          </div>

          {formData.services == "Others" && (
            <div className={`mt-1 ${popup ? "mt-3" : ""}`}>
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="other"
                name="other"
                id="other"
                placeholder="Enter your service name "
                value={formData.other}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {formData.services == "Website Redesigning" && (
            <div className={`mt-1 ${popup ? "mt-3" : ""}`}>
              <input
                className=" bg-white  w-full py-[6px] border px-4 rounded focus:border-blue-500 focus:border outline-none"
                type="domain"
                name="domain"
                id="domain"
                placeholder="Enter your website domain name "
                value={formData.domain}
                onChange={handleChange}
                required
              />
            </div>
          )}

          <div className={`mt-2 ${popup ? "mt-3" : ""}`}>
            <button
              className="bg-orange-500 w-full py-2 font-bold text-white rounded-md "
              type="submit"
            >
              Get Started
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HeroForm;
