import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import handleShowPopUPForm from "../Fuction";
import { useNavigate } from "react-router-dom";

const FreeConsultation = () => {
  const navigate = useNavigate();
  const ImageData = [
    {
      title: "Select A Service",
      image: {
        src: "/Services (1).png",
        alt: "Select A Service Image",
      },
      description:
        "Let's Get Started By Choosing Right Service For Your Business Like Website Designing, Website Development, eCommerce website designing etc.",
    },
    {
      title: "Talk to Our Strategist",
      image: {
        src: "/Services (2).png",
        alt: "Talk to Our Strategist Image",
      },
      description:
        "One Of Our Digital Expert Will Get In Touch With You To Understand Your Requirement And Will Share The Proposal With Best Prices.",
    },
    {
      title: "Let's Start the Project",
      image: {
        src: "/Services (3).png",
        alt: "Let's Start the Project Image",
      },
      description:
        "Once You Choose Us For Your Valuable Project, We Will Get Started With The Project And A Dedicated Project Manager With The Expert Team Will Be Assigned To You.",
    },
  ];

  return (
    <div className="py-12">
      <div>
        <h1 className=" headingcolor text-2xl md:text-3xl lg:text-5xl font-bold text-center">
          Book A Free 30 Mins. Expert Consultation Now !
        </h1>
        <div className=" max-lg:w-[80%] mt-8 grid  grid-cols-1 lg:grid-cols-3  gap-10 xl:max-w-screen-xl mx-auto">
          {ImageData.map((val, i) => {
            return (
              <div key={i}>
                <div>
                  <img
                    className="mx-auto block"
                    src={val.image.src}
                    alt="loading.."
                  />
                </div>
                <h1 className="text-2xl font-bold mt-4 text-center headingcolor">
                  {val.title}
                </h1>
                <p className="text-center  text-paraColor text-sm mt-4">
                  {val.description}
                </p>
              </div>
            );
          })}
          <div></div>
        </div>
        <a
          href="https://calendly.com/thegronity/30min "
          className=" max-md:text-center max-md:text-sm raleway w-[80%] md:w-fit bg-orange-500 md:px-8 py-2 text-white font-bold mx-auto block my-4 zoombutton rounded-lg hover:bg-white hover:border-orange-500  border border-transparent hover:text-orange-500 transition-all delay-300 "
        >
          Book A Free 30 Mins. Meeting
        </a>
        <div className=" text-white font-bold w-[90%] md:w-fit mx-auto flex max-md:flex-col  gap-4">
          <a
            href="tel:+917669177583"
            className=" hover:scale-[.9] transition-all duration-300 bg-orange-500 px-8 py-2 text-xl rounded-lg flex justify-center items-center gap-3 "
          >
            <FaPhoneAlt /> <span> +917669177583</span>{" "}
          </a>
          <button
            className="hover:scale-[.9] raleway transition-all duration-300 border-orange-500 border text-orange-500 px-4 py-2  text-lg rounded-lg"
            onClick={handleShowPopUPForm}
          >
            Get A Free Consultation
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreeConsultation;
