import React, { useEffect } from "react";
import LandingPage from "./pages/LandingPage";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Thankyou from "./pages/Thankyou";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Christmas from "./components/christmas/Christmas";

const App = () => {
  useEffect(() => {
    if (window.location.pathname === "" || window.location.pathname === "/") {
      window.location.href = "https://www.thegronity.com";
    }
  }, [window.location.pathname]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Christmas />} /> */}
          <Route path="/website-development" element={<LandingPage />} />
          <Route path="/thank-you" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} hideProgressBar={true} />
    </>
  );
};

export default App;
