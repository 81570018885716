import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoStarFill } from "react-icons/go";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// const reviews = [

//   {
//     text: "I can't thank Elbroz Media enough for their exceptional website design and development services. Our website now looks flawlessly. Their dedication to delivering quality is remarkable. Thanks",
//     image: "https://elbrozmedia.com/wp-content/uploads/2023/09/search.png",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/image-1-1.jpg",
//     authorName: "Acharya Vijay",
//     position: "Founder, Hanumandham",
//     rating: 5,
//   },
//   {
//     text: "Best experience ever got From a web designer. I have got world class website with great graphics. There Digital Strategies are excellent to boost your online business. I recommend Elbroz for best web designing and development services.",
//     image: "https://elbrozmedia.com/wp-content/uploads/2023/07/Anshuiya.jpeg",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/07/Anshuiya.jpeg",
//     authorName: "Dr. Anasuya Roy",
//     position: "Director, Nanosafesolutions",
//     rating: 5,
//   },
//   {
//     text: "One of Best E-commerce Website Development company in Delhi is Elbroz Media. Team is Full of Enthusiastic Web Designers, Developers & Digital Marketing Staff. Visit There Office and Meet the Team Understand there Excellence. Keep it Up!!!",
//     image: "https://elbrozmedia.com/wp-content/uploads/2023/07/Abhishek.jpeg",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/07/Abhishek.jpeg",
//     authorName: "Abhishek Vats",
//     position: "Director, Makerman",
//     rating: 5,
//   },
//   {
//     text: "Elbroz Media has completely transformed our company. Their digital marketing methods have raised our internet presence tremendously, and our website is a work of art. We are overjoyed with the results!",
//     image:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/anusha-randhawa.jpg",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/anusha-randhawa.jpg",
//     authorName: "Anusha Randhawa",
//     position: "Director, DOM",
//     rating: 5,
//   },
//   {
//     text: "Highly professional and Intelligent team of Elbroz Media created our website and also working on for digital marketing. I am really satisfied with this company and willing to work with them for a long time. Highly recommended.",
//     image: "https://elbrozmedia.com/wp-content/uploads/2023/07/Ashima.jpeg",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/07/Ashima.jpeg",
//     authorName: "Ashima Jain",
//     position: "Director, Vegssup",
//     rating: 5,
//   },
//   {
//     text: "Amazing Website Designing Services Provided By Elbroz. Great Designing & Development Team with Outstanding Working Skills. Fully Satisfied and Really Happy to get there Services. Happy Website. Thanks Elbroz You Guys are Highly Recommended.",
//     image:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/ei_1695183891855-removebg-preview.png",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/ei_1695183891855-removebg-preview.png",
//     authorName: "Dr. Sachin Phirke",
//     position: "Orthopedic Surgeon",
//     rating: 5,
//   },
//   {
//     text: "Outstanding work from Elbroz Media! They created a website for my business that exceeded my expectations. It's sleek, user-friendly, and has boosted my online presence significantly. Thanks a million!",
//     image: "https://elbrozmedia.com/wp-content/uploads/2023/09/images.png",
//     authorImage:
//       "https://elbrozmedia.com/wp-content/uploads/2023/09/images.png",
//     authorName: "Mini Gautam",
//     position: "Advocate, Arthavat Law Offices",
//     rating: 5,
//   },
// ];

const reviews = [
  {
    text: "I can't thank Gronity Web Solution enough for their exceptional website design and development services. Our website now looks flawlessly. Their dedication to delivering quality is remarkable. Thanks",
    // authorImage: "",
    authorName: "Acharya Vijay",
    position: "Founder, Hanumandham",
    rating: 5,
  },
  {
    text: "Best experience ever got from a web designer. I have got a world-class website with great graphics. Their digital strategies are excellent to boost your online business. I recommend Gronity Web Solution for the best web designing and development services.",
    // authorImage: "",
    authorName: "Dr. Anasuya Roy",
    position: "Director, Nanosafesolutions",
    rating: 5,
  },
  {
    text: "One of the best e-commerce website development companies in Delhi is Gronity Web Solution. The team is full of enthusiastic web designers, developers & digital marketing staff. Visit their office and meet the team to understand their excellence. Keep it up!",
    // authorImage: "",
    authorName: "Abhishek Vats",
    position: "Director, Makerman",
    rating: 5,
  },
  {
    text: "Gronity Web Solution has completely transformed our company. Their digital marketing methods have raised our internet presence tremendously, and our website is a work of art. We are overjoyed with the results!",
    // authorImage: "",
    authorName: "Anusha Randhawa",
    position: "Director, DOM",
    rating: 5,
  },
  {
    text: "Highly professional and intelligent team of Gronity Web Solution created our website and also working on digital marketing. I am really satisfied with this company and willing to work with them for a long time. Highly recommended.",
    // authorImage: "",
    authorName: "Ashima Jain",
    position: "Director, Vegssup",
    rating: 5,
  },
  {
    text: "Amazing website designing services provided by Gronity Web Solution. Great designing & development team with outstanding working skills. Fully satisfied and really happy to get their services. Happy website. Thanks Gronity Web Solution. You guys are highly recommended.",
    // authorImage: "",
    authorName: "Dr. Sachin Phirke",
    position: "Orthopedic Surgeon",
    rating: 5,
  },
  {
    text: "Outstanding work from Gronity Web Solution! They created a website for my business that exceeded my expectations. It's sleek, user-friendly, and has boosted my online presence significantly. Thanks a million!",
    // authorImage: "",
    authorName: "Mini Gautam",
    position: "Advocate, Arthavat Law Offices",
    rating: 5,
  },
];

const ReviewCarousel = () => {
  return (
    <div id="review" className="bg-[#bcbcbc]/40 py-12 mt-12">
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper  w-[90%] mx-auto "
      >
        {reviews.map((review, i) => (
          <SwiperSlide className="pb-8" key={i}>
            <div className="relative p-8 bg-white rounded-lg ">
              {/* md:min-h-[288px] lg:min-h-[240px] */}
              <div>
                <img
                  className="w-[40px] mx-auto block  rounded-[50%]"
                  src="/google.png"
                  alt="loading..."
                />
              </div>

              <p className="mt-4 reviews text-justify raleway">{review.text}</p>
              <div className="absolute w-[15px] rotate-45 bottom-[-7.5px] left-1/2 -translate-x-1/2 h-[15px] bg-white"></div>
            </div>

            <div className="flex gap-4 w-fit mx-auto mt-4 p-4">
              <div>
                <img
                  className="w-[50px] rounded-[50%]"
                  src="/user.jpg"
                  alt="loading..."
                />
              </div>
              <div>
                <h1 className="font-semibold raleway">{review.authorName}</h1>
                <p className="">{review.position}</p>
                <span className="flex gap-[1px] mt-1">
                  {Array.from({ length: review.rating }).map((_, starIndex) => (
                    <GoStarFill key={starIndex} />
                  ))}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ReviewCarousel;
