import React from "react";
import { TiSocialTwitter } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import handleShowPopUPForm from "../Fuction";

const Footer = () => {
  const data = [
    {
      icon: "true",
      title: "Services",
      links: [
        { text: "Website Designing" },
        { text: "Website Development" },
        { text: "eCommerce Development" },
        {
          text: "Digital Marketing",
        },
      ],
    },
    {
      title: "Useful Links",
      links: [
        {
          text: "Privacy Policy",
          url: "https://thegronity.com/privacy-policy/",
        },
        {
          text: "Terms and Conditions",
          url: "https://thegronity.com/terms-and-conditions/",
        },
        { text: "Contact Us" },
      ],
    },
    {
      title: "Contact Information",
      links: [
        { text: "India:+917669177583", url: "tel:+917669177583" },
        {
          text: "Email:info@thegronity.com",
          url: "mailto:info@thegronity.com",
        },
      ],
    },
  ];

  const handleServices = (val) => {
    val.title === "Services" && handleShowPopUPForm();
  };
  const handleContactUs = (val) => {
    val.text === "Contact Us" && handleShowPopUPForm();
  };

  return (
    <footer className="raleway pt-12 pb-4 px-8   bg-[#bcbcbc]/40   ">
      <div className=" flex  flex-col xl:flex-row  justify-between ">
        <div className=" ">
          <div className=" max-xl:w-fit max-xl:mx-auto">
            <img
              width="215"
              height="67"
              loading="lazy"
              src="logo.png"
              className=" max-sm:w-[150px]"
              alt=""
            />
          </div>
          <p className="text-lg md:text-2xl font-semibold mt-4 max-xl:text-center">
            Best website design company in Delhi
          </p>
          <div className="flex max-xl:justify-center  max-xl:gap-8 gap-4  text-2xl mt-4">
            <a href="https://www.facebook.com/thegronity">
              <FaFacebook className="hover:text-orange-500 transition-all" />
            </a>
            <a href="https://www.instagram.com/thegronity/">
              <FaInstagram className="hover:text-orange-500 transition-all" />
            </a>
            <a href="https://twitter.com/theGonity">
              <TiSocialTwitter className="hover:text-orange-500 transition-all" />
            </a>
            <a href="https://www.linkedin.com/company/thegronity/">
              <FaLinkedin className="hover:text-orange-500 transition-all" />
            </a>
            <a href="https://www.youtube.com/@TheGronity/">
              <FaYoutube className="hover:text-orange-500 transition-all" />
            </a>
          </div>
        </div>

        {data.map((val, i) => {
          return (
            <div key={i} className="max-xl:hidden">
              <div>
                <h1 className="text-2xl font-bold ">{val.title}</h1>
                <div className="mt-4">
                  {val.links.map((value, j) => {
                    return (
                      <div  key={j} className="flex items-center my-1  w-fit hover:text-orange-500 transition-all ">
                        {val.icon ? (
                          <MdChevronRight className="text-xl" />
                        ) : null}

                        <a className="block"  href={value?.url}>
                          <button
                            onClick={() => {
                              handleServices(val);
                              handleContactUs(value)
                            }}
                            // onClick={}
                          >
                            {value.text}
                          </button>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}

        <div className=" max-xl:mt-8 flex  max-md:flex-col  justify-between xl:hidden">
          {data.map((val, k) => {
            return (
              <div key={k} className="">
                <div>
                  <h1 className="text-2xl font-bold ">{val.title}</h1>
                  <div className="mt-4">
                    {val.links.map((value,l) => {
                      return (
                        <div key={l} className="flex items-center my-1  w-fit hover:text-orange-500 transition-all ">
                          {val.icon ? (
                            <MdChevronRight className="text-xl" />
                          ) : null}

                           <a className="block" href={value?.url}>
                          <button
                            onClick={() => {
                              handleServices(val);
                              handleContactUs(value)
                            }}
                           
                          >
                            {value.text}
                          </button>
                        </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <hr className="my-4 h-[2px] bg-black" />
      <div>
        <p>
          Copyright © 2024 <a href="/">Gronity Web Solution Pvt. Ltd.</a>
        </p>
        <div></div>
      </div>
    </footer>
  );
};

export default Footer;
