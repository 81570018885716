import React from "react";
import handleShowPopUPForm from "../Fuction";
import HeroForm from "./Form/HeroForm";

const DiscussionWithExperts = () => {
  return (
    <div className="py-8 ">
      <div className=" w-[90%] max-lg:w-[80%] mt-8 flex max-lg:flex-col items-center xl:max-w-screen-xl mx-auto ">
        <div className="lg:w-1/2">
          <h1 className="headingcolor text-lg md:text-3xl  font-bold">
            Initiate the journey with a straightforward conversation with our
            expert.
          </h1>
          <div>
            <img
              className="w-full md:h-[400px]"
              src="/16e6df9d5eceacc167f4aff096dcde9e.png"
              alt="loading..."
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 ">
          <div className=" p-8 rounded-[10px]  bg-gray-200/50 shadow-[0_0_3px_#000] ">
            <h1 className="headingcolor text-xl md:text-3xl text-center font-bold">
              Let's discuss Your project
            </h1>
            <p className="text-center mt-2 max-md:text-[12px] text-gray-500">
              {" "}
              Accelerating Revenues For More Than 2500+ Companies{" "}
            </p>
            <HeroForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscussionWithExperts;
