import React from "react";

const ImageSlider = ({ image }) => {
  return (
    <div className={"ImageSlider  shadow-[0px_0px_10px_0px_rgba(0,0,0,0.5)]"}>
      <div className="w-full h-[35vh] lg:h-[70vh] overflow-hidden   ">
        <img
        loading="lazy"
          className="w-full  hover:-translate-y-[calc(100%-35vh)]  lg:hover:-translate-y-[calc(100%-70vh)] transition-all duration-[5000ms]"
          src={image}
          alt="loading..."
        />
      </div>
    </div>
  );
};

export default ImageSlider;
