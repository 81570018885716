import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import HeroForm from "./Form/HeroForm";
import handleShowPopUPForm from "../Fuction";
import Snowfall from "react-snowfall";

const Hero = () => {
  const data = [
    {
      value: 6,
      title: "Years of Experience",
    },
    {
      value: "800",
      title: "Customer Satisfied",
    },
    {
      value:" 2.5k",
      title: "Leads Generated",
    },
    {
      value: 4,
      title: "Countries Served",
    },
  ];

  return (
    <section className="">
      {/* <Snowfall color="white" snowflakeCount={100} /> */}
      <div className="flex max-lg:flex-col  max-lg:mt-8   gap-8 items-center lg:py-12  px-8">
        <div className=" w-full lg:w-[70%] ">
          <h1 className="headingcolor text-2xl max-lg:text-center md:text-5xl font-bold text-headingBlack">
            Top Website Designing <br /> Company in Delhi
          </h1>
          <p className=" mt-4 max-md:text-center text-sm md:text-xl text-gray-500 font-normal">
            {/* Accelerating revenues for more than 2500+ companies for 12+ years,
            Whatever Your Business Needs to Achieve it’s Goals, We Will Make it
            Happen!"{" "} */}
            Elevate your online presence with Delhi's leading website design company. Our creative team combines innovation with technology, delivering tailored solutions for diverse industries. We prioritize performance and user satisfaction, fostering a collaborative partnership for sustained success. Choose us for an exceptional digital identity in Delhi's dynamic online landscape.
          </p>
          <div className="flex max-md:flex-col  gap-4 mt-4 text-sm md:text-xl">
            <button
              className="hover:scale-[.9] transition-all duration-300 bg-orange-500 px-4 py-2 rounded-md text-white font-bold"
              onClick={handleShowPopUPForm}
            >
              Request A Proposal
            </button>
            <a href="tel:+917669177583" className="hover:scale-[.9] transition-all duration-300 flex justify-center gap-2 items-center font-bold text-orange-500 rounded-md bg-white border px-4 py-2   border-orange-500">
              <FaPhoneAlt />
              <span>+91-7669177583</span>
            </a>
          </div>
          <div className="max-md:hidden grid  grid-cols-2 md:grid-cols-4">
            {data.map((val, i) => {
              return (
                <div className="py-4 text-center" key={i}>
                  <h1 className="text-3xl font-bold headingcolor ">{val.value}+</h1>
                  <p className="font-bold  text-gray-500">{val.title}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full lg:w-[30%]">
          <div className="px-8 py-6 rounded-[10px]  bg-gray-200/50 shadow-[0_0_3px_#000] ">
            <div className="text-center">
              <h2 className="headingcolor text-lg md:text-2xl font-bold">
                Book a Free Consultation
              </h2>

              <p className="text-[12px] leading-[18px] md:leading-normal md:text-lg text-gray-500 font-bold ">
                Let’s understand your requirements.
              </p>
            </div>

            <div>
              <HeroForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
