import React from "react";

const SentaClause = () => {
  return (
    <>
      <div className="fixed z-40 b w-[250px] right-[-300px] bottom-4 scroll-container ">
        <img
          className=" w-[400px] scroll-image"
          // src="/santa.gif"
          src="/plane.png"
          alt="loading..."
        />
      </div>

      <style>{`
    
    
    .scroll-image{
      margin-left:auto ;
      margin-right:0

    }
    .scroll-image {
      animation: scrollAnimation 20s linear infinite; /* Adjust the duration as needed */
    }
    
    @keyframes scrollAnimation {
      0% {
        transform: translateX(0);
      }
      // 50%{
      //   transform: translateY(-50vh); 
      // }
      100% {
        transform: translateX( calc(-120vw)); /* Adjust the percentage based on your content */
      }
    }
    
    
    `}</style>
    </>
  );
};

export default SentaClause;
