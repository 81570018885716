import React from "react";
import ImageSlider from "../ImageCarousel/ImageSlider";

const Portfolio = () => {
  const imageData = [
    "/download.webp",
   "/Portfolio (1).webp",
   "/Portfolio (2).webp",
   "/Portfolio (3).webp",
   "/Portfolio (4).webp",
   "/Portfolio (5).webp",
   "/Portfolio (6).webp",
   "/Portfolio (7).webp",
  ];
  return (
    <div className="py-12 px-2">
      <div className="text-center">
        <h1 className="headingcolor text-2xl md:text-5xl font-bold">Our Portfolio</h1>
        <p className="mt-2 max-md:text-[12px] text-paraColor ">
          {" "}
          Only pay if you're pleased with our work. We offer a hassle-free
          money-back policy with no questions asked.
        </p>
      </div>
      <div className=" grid grid-cols-2 lg:grid-cols-4 gap-4  mt-6  ">
        {imageData.map((val, i) => {
          return <ImageSlider key={i} image={val} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
