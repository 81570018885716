import React from "react";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { TiSocialTwitter } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Thankyou = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        {/* Facebook Pixel Code */}
        <script>{`
          !function(f,b,e,v,n,t,s){
            if(f.fbq)return;
            n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
          
          fbq('init', '3010607305874228');
          fbq('track', 'PageView');
        `}</script>

        {/* Noscript fallback */}
        <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=3010607305874228&ev=PageView&noscript=1"
            alt="Facebook Pixel"
          />
        `}</noscript>
      </Helmet>
      <section className="h-screen  flex flex-col justify-evenly relative ">
        <div className="w-[90%] md:w-[80%] lg:max-w-screen-lg  mx-auto text-center ">
          <IoCheckmarkCircleSharp className="text-green-400 text-6xl mx-auto" />

          <h1 className="text-3xl lg:text-5xl font-bold mt-4 ">
            Thank You for showing interest!
          </h1>
          <p className="text-sm md:text-lg lg:text-2xl mt-4 text-gray-500">
            We appreciate your time and interest in our services. If you have
            any questions or need further assistance, feel free to{" "}
            <a href="mailto:https://thegronity.com/">contact us</a>.
          </p>
          <p className="text-sm md:text-lg lg:text-2xl text-gray-500 mt-4">
            Stay tuned for updates and exciting news!
          </p>
        </div>
        <div className="flex  max-md:flex-col  gap-12 w-fit mx-auto  mt-12">
          <div className="  ">
            <h2 className="text-lg max-lg:text-center md:text-3xl font-semibold text-headingBlack">
              Contact with us{" "}
            </h2>
            <div className="flex max-xl:justify-center  max-xl:gap-8 gap-4  text-2xl mt-6">
            <a href="https://www.facebook.com/thegronity">
                <FaFacebook className="hover:text-orange-500 transition-all" />
              </a>
              <a href="https://www.instagram.com/thegronity/">
                <FaInstagram className="hover:text-orange-500 transition-all" />
              </a>
              <a href="https://twitter.com/theGonity">
                <TiSocialTwitter className="hover:text-orange-500 transition-all" />
              </a>
              <a href="https://www.linkedin.com/company/thegronity/">
                <FaLinkedin className="hover:text-orange-500 transition-all" />
              </a>
              <a href="https://www.youtube.com/@TheGronity/">
                <FaYoutube className="hover:text-orange-500 transition-all" />
              </a>
            </div>
          </div>
          <div className="">
            <h2 className="text-lg max-lg:text-center md:text-3xl font-semibold text-headingBlack">
              Visit our Website{" "}
            </h2>
            <a
              href="https://thegronity.com/"
              className="text-center mt-4 block text-white max-lg:mx-auto max-md:block bg-orange-500 px-8 py-2 md:text-2xl font-bold rounded-lg"
            >
              Visit Website
            </a>
          </div>
        </div>

        <div className="bg-orange-500 w-[250px] h-[250px]  md:w-[500px] md:h-[500px] absolute rotate-45 top-[-125px] left-[-125px]  md:top-[-250px] md:left-[-250px] z-[-1]"></div>
      </section>
    </>
  );
};

export default Thankyou;
