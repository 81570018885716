import React from "react";

const BottomPopUp = () => {
  return (
    <div className="max-md:hidden fixed slideUpDown left-4 bottom-4  bg-white z-30 rounded  border-blue-950 border-2 shadow-[0_2px_5px_#000]  ">
      <div className=" raleway flex gap-4 items-center px-4 py-4  ">
        <img className="w-[250px]" src="/PopUp republic.png" alt="loading..." />
        {/* <p className="text-[10px] font-bold">
          158 people satisfied in last week
        </p>
        <div className="w-[50px] b overflow-hidden">
          <video
            className="m-auto  object-cover"
            loading="lazy"
            muted="muted"
            src="/chakr.mp4"
            type="video/mp4"
            autoplay="autoplay"
            loop="loop"
          ></video>
        </div> */}
      </div>
    </div>
  );
};

export default BottomPopUp;
