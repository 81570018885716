import React from "react";
import handleShowPopUPForm from "../Fuction";

const OurWork = () => {
  // {
  //     "agency": {
  //       "name": "Award-Winning Website Designing Agency In Delhi",
  //       "description": "We Design & Manage India’s Leading Corporate, Business & eCommerce Websites."
  //     },
  const services = [

    {
      img:"/OurWork1.png",
      type: "Website For Businesses",
      description:
        "Unleash your business's full potential with our tailor-made website designs. From bold startups to seasoned enterprises, our team crafts engaging online solutions that captivate audiences and drive success across all industries.",
      quotationLink: "Get Exact Quotation",
    },
    { img:"/Ourwork2.png",
      type: "Website For Corporates",
      description:
        "Elevate your corporate brand with our website designs. From Fortune 500 giants to innovative startups, our team creates sleek and impactful platforms that showcase your professionalism and drive business growth.",
      quotationLink: "Get Exact Quotation",
    },
    {
      img:"/OurWork.png",
      type: "eCommerce Website",
      description:
        "Step into the world of seamless online shopping with our stunning eCommerce website designs. From user-friendly interfaces to secure payment gateways, we create captivating online stores that turn browsers into loyal customers.",
      quotationLink: "Get Exact Quotation",
    },
  ];
  //   }

  return (
    <section className="py-16  ">
      <div className="px-2">
        <h1 className="headingcolor text-2xl md:text-5xl text-center   font-bold">
          Award-Winning Website Designing Agency In Delhi
        </h1>
        <p className="text-center mt-4 text-paraColor text-[12px] md:text-2xl ">
          We Design & Manage India’s Leading{" "}
          <span className="font-bold ">Corporate, Business & eCommerce </span>
          Websites.
        </p>
      </div>
      <div className=" max-lg:w-[80%] mt-8 grid  grid-cols-1 lg:grid-cols-3  gap-10 xl:max-w-screen-xl mx-auto">
        {services.map((val, i) => {
          return (
            <div key={i} className="">
              <div className="md:h-[240px]">
                <img
                loading="lazy"
                className="w-[70%] h-full   mx-auto object-cover "
                src={val.img}
                  // src="https://elbrozmedia.com/wp-content/uploads/2023/07/website-for-buisness-1.png"
                  alt="loading..."
                />
              </div>
              <div>
                <h1 className="headingcolor mt-3 text-center  text-2xl  font-bold">
                  {val.type}
                </h1>
                <p className=" text-[14px] mt-3 text-center">{val.description}</p>
                <button className="hover:scale-[.9] transition-all duration-300 mt-8 py-2 font-bold text-white rounded bg-orange-500 w-[80%] mx-auto block m"
                onClick={handleShowPopUPForm}
                >Get Exact Quotation</button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurWork;
