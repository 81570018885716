import React, { useState } from "react";

const ImageCarousel = () => {
  const imageAccordion = [
    {
      imageSrc: "/ImageCarousel/aaaa.jpg",
      title: "Real Estate Projects",
      content:
        "Transforming real estate engagement at Gronity—Explore our cutting-edge solutions.",
    },
    {
      imageSrc: "/ImageCarousel/health.jpg",
      title: "Health Website Projects",
      content:
        "Explore high-quality healthcare websites at Gronity—Seamless online experiences to elevate your healthcare presence.",
    },
    {
      imageSrc: "/ImageCarousel/ecommerce.jpg",
      title: "E-Commerce Website Projects",
      content:
        "Explore Gronity, your ultimate hub for innovative e-commerce solutions, redefining online retail experiences.",
    },
    {
      imageSrc: "/ImageCarousel/cleaning-website.jpg",
      title: "Blog Website Project",
      content:
        "Elevate your blog with Gronity – where compelling content meets seamless storytelling.",
    },
  ];

  return (
    <div className="flex max-md:flex-col">
      {imageAccordion.map((val, i) => (
        <div
          key={i}
          className={`relative md:ss max-md:h-[100px] max-md:hover:h-[500px]   md:flex-[2] md:hover:flex-[5] z-[10]  transition-all duration-500  group  h-[50vh] overflow-hidden 
          `}
        >
          <img
            loading="lazy"
            className="absolute z-[-9] w-full h-full"
            src={val.imageSrc}
            alt="loading..."
          />
          <div className="w-full h-full bg-black/60 flex text-center flex-col items-center justify-center  text-white">
            <h1 className="transition-all text-white delay-300 duration-500 text-[10px] md:text-2xl fontbold translate-y-[-30vh] group-hover:translate-y-0   ">
              {val.title}
            </h1>
            <p className="mt-4 md:mt-4 transition-all delay-300 text-center text-[8px] md:text-base  duration-500 translate-y-[30vh] group-hover:translate-y-0 ">
              {val.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageCarousel;
